import { Component, OnInit, OnDestroy, ViewEncapsulation, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubSink } from 'subsink';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TUser } from 'app/core/models/tenantuser';
import { TenantService } from 'app/core/services/tenant.service';
import { OnboardingService } from './onboarding.service';
import { ToastrService } from 'ngx-toastr';
import { FileObject } from 'app/main/shared/components/file-upload/file-upload.component';
import { ProductService } from 'app/main/admin/products/services/product.service';
import { AppLoadService } from 'app/core/services/appload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from 'app/core/settings/appconstants';
import { AppUtilsService } from 'app/core/services/apputils.service';
import { MatStepper } from '@angular/material/stepper';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent implements OnInit, OnDestroy, AfterViewInit {
  //private
  private bannerImageUrl: string;
  private firstFormGroup: FormGroup;
  private secondFormGroup: FormGroup;
  private subs = new SubSink();
  private thirdFormGroup: FormGroup;

  //public
  public bannerImage: { id: number; url: string }[] = [];
  public intervalTime: any;
  public isLoading: boolean;
  public isLoadingFlag: boolean = false;
  public isResendVerification: boolean = false;
  public phone: any;
  public tenant: any;
  public user: any;

  public timeZoneList = [
    { value: 'America/Los_Angeles', text: 'America/Los_Angeles - PST', countryCode: '+1 -' },
    { value: 'America/Denver', text: 'America/Denver - MST', countryCode: '+1 -' },
    { value: 'America/Chicago', text: 'America/Chicago - CST', countryCode: '+1 -' },
    { value: 'America/New_York', text: 'America/New_York - EST', countryCode: '+1 -' },
    { value: 'Asia/Kolkata', text: 'Asia/Kolkata - IST', countryCode: '+91 -' },
    { value: 'America/Grand_Turk', text: 'America/Grand_Turk - GMT', countryCode: '+1 -' },
    { value: 'America/Anguilla', text: 'America/Anguilla - AST', countryCode: '+1 -' },
  ];

  bannerImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _tenantUserService: TenantUserService,
    private _appSettinService: AppSettingsService,
    private _onboardingService: OnboardingService,
    private SpinnerService: NgxSpinnerService,
    private _router: Router,
    public toastr: ToastrService,
    private appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.bindFormData(this.tenant);
    this.initBannerImage();
    this.hideApp(true);
    this.getTenantUserService();
  }

  ngAfterViewInit(): void {
    if (this.stepper && this.tenant) {
      this.stepper.selectedIndex = this.tenant.onBoardingStep;
    }
  }

  // private methods

  private bindFormData(data) {
    this.firstFormGroup = this._formBuilder.group({
      businessType: [data?.businessType ||'', Validators.required],
      businessSize: [data?.businessSize ||'', Validators.required],
      businessReferer: [data?.businessReferer || ''],
    });

    this.secondFormGroup = this._formBuilder.group({
      name: [data?.name || '', Validators.required],
      logo: [data?.logo || ''],
      timezone: [data?.timezone || '', Validators.required],
      website: [data?.companyUrl || ''],
    });

    this.thirdFormGroup = this._formBuilder.group({
      isShowCoursesMenu: [true],
      isShowScheduleMenu: [data?.isShowScheduleMenu],
      isShowEventsMenu: [data?.isShowEventsMenu],
      isShowAssessment: [data?.isShowAssessment],
      isShowDonationMenu: [data?.isShowDonationMenu],
      isShowMerchandiseMenu: [data?.isShowMerchandiseMenu],
    });
  }

  private getTenantUserService() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.subs.add(
      this._tenantUserService.user.subscribe((user: TUser) => {
        console.log('user', user);
        this.user = user;
        const isStudentRole = TenantUserService.isStudent(this.user);
        if (!this.user.isFirstLogin && isStudentRole) {
          if (this.user.isEmailVerified) {
            this.stopInterval();
            this.redirectState();
          } else {
            this.isResendVerification = true;
            this.resendVerification();
            this.intervalTime = setInterval(() => {
              if (!this.user.isEmailVerified) {
                this.isVerificationCheck();
              } else {
                this.redirectState();
              }
            }, 10000);
          }
        }
        this.getTenantService();
      }),
    );
  }

  private getTenantService() {
    this.subs.add(
      this._appSettinService.accountDetails$.subscribe((tenant: any) => {
        console.log('tenant', tenant);
        this.getCountryCode(tenant);
        this.tenant = tenant;
        const isStaff = this._tenantUserService.isStaffRole();
        if ((this.tenant.isNewTenant && isStaff)) {
          if (this.stepper) {
            this.stepper.selectedIndex = this.tenant.onBoardingStep;
            this.bindFormData(this.tenant);
          }
        }
        this.isLoading = false;
        this.SpinnerService.hide();
      }),
    );
  }

  private getCountryCode(tenant) {
    this.phone = AppUtilsService.getCountryDialCode(tenant.timezone) + '-';
  }

  private hideApp(isHide: boolean, isNotify: boolean = true) {
    // Configure the layout
    this._fuseConfigService.setConfig(
      {
        layout: {
          navbar: {
            hidden: isHide,
          },
          toolbar: {
            hidden: isHide,
          },
          footer: {
            hidden: isHide,
          },
          sidepanel: {
            hidden: isHide,
          },
        },
      },
      isNotify,
    );
  }

  private initBannerImage() {
    this.subs.add(
      this.bannerImage$.subscribe(res => {
        this.bannerImage = res;
        let list = this.bannerImage[0].url.split('/');
        let length = list.length;
        if (length > 1) {
          this.bannerImageUrl = `${list[length - 2]}/${list[length - 1]}`;
          this.secondFormGroup.get('logo').setValue(this.bannerImageUrl);
        } else {
          this.secondFormGroup.get('logo').setValue('');
        }
      }),
    );
  }

  private isVerificationCheck() {
    console.log('isVerificationCheck');
    this._onboardingService.getTenantDetails().subscribe(user => {
      console.log(user);
      this.user = user;
      if (!user) {
        this.stopInterval();
        return;
      }
      if (this.user.isEmailVerified) {
        this.stopInterval();
        this.redirectState();
      }
    });
  }

  private redirectState() {
    this._router.navigate([AppConstants.SL_CLASSES_ROUTE]);
    this.hideApp(false);
  }

  private resendVerification() {
    if (!this.user.isEmailVerified) {
      let redirectUrl = 'https://' + window.location.hostname;
      let payload = {
        redirectUrl: redirectUrl,
      };
      this.isLoadingFlag = true;
      this._onboardingService.resendVerification(payload, this.user.guId).subscribe(contactResponse => {
        console.log(contactResponse);
        this.isLoadingFlag = false;
        this.toastr.success('Sent verification email');
      });
    }
  }

  private stopInterval = function () {
    clearInterval(this.intervalTime);
  };

  //public methods

  public buildImage(url) {
    return AppUtilsService.buildCloudinaryImageUrl(this.appLoadService.tenantConfig.cloudName, url, 0, 0, false);
  }

  public next() {
    if (this.stepper.selectedIndex === 0 && this.firstFormGroup.valid) {
      this.SpinnerService.show()
      this.saveToBackend(this.firstFormGroup.value);
    } else if (this.stepper.selectedIndex === 1 && this.secondFormGroup.valid) {
      this.SpinnerService.show()
      this.saveToBackend(this.secondFormGroup.value);
    } else if (this.stepper.selectedIndex === 2 && this.thirdFormGroup.valid) {
      this.SpinnerService.show()
      this.saveToBackend(this.thirdFormGroup.value);
    }
  }

  public saveToBackend(currentStepData) {
    const payload = {
      ...currentStepData,
      logo: this.secondFormGroup.get('logo').value,
      onBoardingStep: this.stepper.selectedIndex,
    };
    this._onboardingService.updateTenantService(payload).subscribe(
      response => {
        this._appSettinService.updatePluginTenant(payload).subscribe(
          response => {
            this.SpinnerService.hide()
            this.stepper.next();
          },
          error => {
            this.SpinnerService.hide()
            console.log('Error saving data');
          },
        );
      },
      error => {
        this.isLoading = false;
        this.SpinnerService.hide()
        console.log('Error saving data');
      },
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
