import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomField } from '../../models/custom-field.model.ts';
import { SubSink } from 'subsink';
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { AppUtilsService } from 'app/core/services/apputils.service';


@Component({
  selector: 'app-custom-field-dlg',
  templateUrl: './add-custom-field-dlg.component.html',
  styleUrls: ['./add-custom-field-dlg.component.scss'],
})
export class AddCustomFieldDlgComponent implements OnInit {
  action: string;
  customField: CustomField;
  public _subs = new SubSink()
  customFieldForm: FormGroup;
  dialogTitle: string;
  isLoading: boolean = false;
  type: any = 'text';
  typeData: any = '';
  public TAGMAXLENGTH: number = 25

  allType: any = [
    { key: 'all', value: 'Select Type' },
    { key: 'text', value: 'Text' },
    { key: 'number', value: 'Number' },
    { key: 'textarea', value: 'Text Area' },
    { key: 'checkbox', value: 'Check Box' },
    { key: 'radio', value: 'Radio Buttons' },
    { key: 'header', value: 'Header' },
    { key: 'dropdown', value: 'Dropdown'}
  ];
  public availableFields: any = [];

  /**
   * Constructor
   *
   * @param {MatDialogRef<AddCustomFieldDlgComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(public matDialogRef: MatDialogRef<AddCustomFieldDlgComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private _formBuilder: FormBuilder,private _appSettingsService: AppSettingsService) {
    // Set the defaults
    this.action = _data == null ? 'add' : _data.action;
    this.type = _data == null ? 'text' : _data.type;
    console.log('_data.type', _data.type);
    if (this.action === 'edit') {
      this.dialogTitle = 'Edit Custom Field';
      this.customField = _data.customField;
    } else {
      this.dialogTitle = 'Create Custom Field';
      this.customField = new CustomField({});
      this.typeData = _data.type;
      this.customField.isFormField = this.typeData == 'all' ? true : false;
      this.type = this.typeData == 'all' ? 'text' : _data.type;
      this.customField.type = this.type;
    }

    this.customFieldForm = this.createCustomFieldForm();
  }

  ngOnInit() {
    this.getAvailableFields();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create customField form
   *
   * @returns {FormGroup}
   */
  createCustomFieldForm(): FormGroup {
    return this._formBuilder.group({
      guId: [this.customField.guId],
      tag: [this.customField.tag, [this.tagInListValidator(),this.tagPatternValidator(),Validators.maxLength(this.TAGMAXLENGTH)]],
      name: [this.customField.name],
      type: [this.customField.type],
      option1: [this.customField.option1],
      option2: [this.customField.option2],
      option3: [this.customField.option3],
      option4: [this.customField.option4],
      option5: [this.customField.option5],
      option6: [this.customField.option6],
      placeholder: [this.customField.placeholder],
      text: [this.customField.text],
      isMandatory: [this.customField.isMandatory],
      isFormField: [this.customField.isFormField],
    });
  }

  public generateTag(name){
    const tagCustomField = this.customFieldForm.get('tag');
    if(!tagCustomField.dirty && name.target.value.length <= this.TAGMAXLENGTH){
      tagCustomField.setValue(AppUtilsService.generateTag(name.target.value));
    }
    this.customFieldForm.controls.tag.markAllAsTouched();
  }
  
  private getAvailableFields() {
    this._subs.add(
      this._appSettingsService.getCustomSettings().subscribe((result:any) => {
        this.availableFields = result;
      }),
    );
  }

  public tagInListValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = !(this.availableFields.map(item => (item.tag)).filter(item=> item != null).includes(control.value));
      return isValid ? null : { nameInList: true };
    }
  }

  public tagPatternValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = /^[a-z0-9]+$/.test(control.value);
      return isValid ? null : { patternMismatch: true };
    }
  }

  // isShowIsMandatoryField() {
  //   return this.type == 'text' || this.type == 'number' || this.type == 'textarea';
  // }
  isShowIsMandatoryField() {
    return this.type == 'checkbox' || this.type == 'text' || this.type == 'number' || this.type == 'textarea';
  }

  isShowOptionField() {
    return this.type == 'radio' || this.type == 'dropdown';
  }
  typeChanged(type) {
    this.type = type;
  }
}
